body {
  background-color: #f8f5f5;
}

.ui.fixed.menu {
  background-image: linear-gradient(
    135deg, 
    rgb(56,0,60) 0%, 
    rgb(79, 2, 85) 69%, 
    rgb(113, 2, 121) 89%
    )
}

.goals-input-field {
  font-size: 30px;
}

.ui.main.container {
  margin-top: 7em;
  margin-bottom: 7em;
}

.ui.icon.menu {
  margin-top: 3.8em;
}

.goalsInputField {
  font-size: 35px;
  text-align: center;
  align-items: center;
}

.home-content {
  background-image: #fff
}

.masthead {
  display: flex;
  align-items: center;
  background-image: linear-gradient(
    135deg, 
    rgb(56,0,60) 0%, 
    rgb(79, 2, 85) 69%, 
    rgb(113, 2, 121) 89%
    ) !important;
  height: 100vh;  
}

.masthead h1.ui.header {
  font-size: 3em
}

.ui.card.fixture-card {
  background-color: #00ff85
}

.ui.dropdown.item.text {
  font-size: 25px;
}

.flexbox-container {
  display: flex;
  flex-direction: row;
}

.scorecard {
  color: #2f2f2f;
  font-size: 1.3rem;
  border: 5px solid #0000;;
  border-color: #0000;
  border-width: 10px
}

.fixturesCard {
  overflow-x: auto;
}